<template>
  <div class="container-fluid">
    <!--  -->
    <div class="row" v-if="!loading && !template">
      <div class="col-md-6 mx-auto">
        <create-template></create-template>
      </div>
    </div>

    <busy :visible="loading"/>

    <!--  -->
    <div class="alert alert-warning">
      <b>Building in template mode.</b>
      <small
        >To write a plan for a client open the health report under their
        name.</small
      >
      <button type="button" class="close" data-dismiss="alert" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
    </div>
    <div
      class="row"
      v-if="!loading && template"
    >
      <div class="col-md-9 page-area-print-wide">
        <form-area :client="client"></form-area>
      </div>
      <div class="col-md-3 noprint">
        <div class="card border-0 bg-secondary mb-3">
            <div class="card-body text-white px-3 py-2">
              <p class="mb-0 text-center">
                {{ $stash.loadedTemplate.title }}
              </p>
            </div>
          </div>
        <form-builder-toolbar :client="client" :template="template" />
        <div style="max-height: 90vh; overflow-y: auto; overflow-x: hidden">
          <form-builder-options
            class="mb-3"
            v-if="$stash.currentSelectedItem"
          ></form-builder-options>
          <form-builder-items
            class="mb-3"
            :client="client"
          ></form-builder-items>
          <how-to></how-to>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CreateTemplate from "./partials/CreateTemplate";
import FormArea from "./partials/FormArea";
import FormBuilderItems from "./partials/FormBuilderItems";
import FormBuilderOptions from "./partials/FormBuilderOptions";
import FormBuilderToolbar from "./partials/FormBuilderToolbar";
import HowTo from "./partials/HowTo";
export default {
  props: {
    inputView: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: true,
      template: null,
      client: null,
    };
  },
  methods: {
    fetchTemplate() {
      this.$axios
        .get(
          process.env.VUE_APP_API_URL + "/documents/" + this.$route.params.id
        )
        .then(({ data }) => {
          this.template = data.template;
          this.checkLoaded();
        });
    },
    updateTemplate(silent) {
      this.$axios
        .post(
          this.inputView
            ? process.env.VUE_APP_API_URL +
                "/documents/client-version/" +
                this.$route.params.id
            : process.env.VUE_APP_API_URL +
                "/documents/" +
                this.$route.params.id,
          {
            data: this.$stash.templateItems,
          }
        )
        .then(({ data }) => {
          this.template = data.template;
          if (!silent) {
            this.$EventBus.$emit("alert", data);
          }
        });
    },
    checkLoaded() {
      if (this.template != null) {
        this.$stash.loadedTemplate = this.template;
        this.$stash.templateItems = this.$stash.loadedTemplate.data;
      }
      this.loading = false;
    },
    clearStore() {
      this.template = null;
      this.$stash.loadedTemplate = null;
      this.$stash.templateItems = [];
      this.$stash.currentSelectedRow = null;
      this.$stash.currentSelectedCol = null;
      this.$stash.currentSelectedColKey = null;
      this.$stash.currentSelectedItem = null;
    },
  },
  mounted() {
    this.$EventBus.$on("saveTemplate", (silent = true) => {
      this.updateTemplate(silent);
    });
  },
  created() {
    this.clearStore();
    this.fetchTemplate();
  },
  beforeDestroy() {
    this.clearStore();
    this.$EventBus.$off("saveTemplate", (silent = true) => {
      this.updateTemplate(silent);
    });
  },
  components: {
    CreateTemplate,
    FormArea,
    FormBuilderItems,
    FormBuilderToolbar,
    FormBuilderOptions,
    HowTo,
  },
};
</script>

<style>
.sticky-top {
  top: 1rem;
}
</style>